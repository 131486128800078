.slide  { position: relative; 
  
  .carouse { width: 100%; overflow: hidden; position: relative; }
  .slideItem .banner-img { display: block; width: 100%; }
  .slideItem { display: block; }
  .carouse .slideItem { width: 100%; position: absolute; } 
  .console { height: 57px; display: inline-block; width: 35px; position: absolute; top: 40%; }
  .dotList { text-align: center; position: absolute; 
    bottom: 11px; left: 50%; margin-left: -72px; }
  .dotList ul li { display: inline-block; width: 22px; height: 22px; background: url(../images/yao_hui.png) no-repeat center center;
    margin-left: 7px; margin-right: 7px; cursor: pointer; }
  .dotList ul li.active { background: url("../images/yao_1.png") no-repeat center center; }
  .carousel-control { position: absolute; top: 50%; width: 91px; height: 91px; z-index: 999; margin-top: -45px; }
  .carousel-control.left { left: 3%; background: url(../images/header_l.png) no-repeat center center; cursor: pointer; }
  .carousel-control.right { right: 3%; background: url(../images/header_r.png) no-repeat center center; cursor: pointer; }
  .carousel-control.left:hover { background: url(../images/header_lh.png) no-repeat center center; }
  .carousel-control.right:hover { background: url(../images/header_rh.png) no-repeat center center; }
  .slidedetail { display: none; }

}


.imgtoVideo img { width: 100%; }

.imgtoVideo > div { padding: 1px; position: relative; cursor: pointer; width: 49%; display: inline-block; }

.imgtoVideo > div:hover .imgtoVideoDetail { display: block; }

.imgtoVideo .imgtoVideoDetail { width: 100%; height: 100%; position: absolute; top: 0; left: 0; padding: 1px; display: none; }

.imgtoVideo .imgtoVideoDetail > div { background: rgba(0, 98, 197, 0.8); filter: progid:DXImageTransform.Microsoft.gradient(startColorstr=#C8142B49,endColorstr=#C8142B49); width: 100%; height: 100%; text-align: center; }

.imgtoVideo .tovideo .imgtoVideoDetail { display: block; }

.imgtoVideo .tovideo .imgtoVideoDetail > div { background: #0C2D47; width: 60px; height: 60px; color: #fff; line-height: 60px; vertical-align: middle; margin: 0 auto; margin-top: 17%; }

.imgtoVideo .imgtoVideoDetail .fa-search { color: #fff; font-size: 25px; margin-top: 21%; }

.contentIV { position: fixed; top: 0; left: 0; right: 0; width: 100%; height: 100%; display: table; background: rgba(20, 43, 73, 0.8); filter: progid:DXImageTransform.Microsoft.gradient(startColorstr=#C8142B49,endColorstr=#C8142B49); margin: 0 auto; z-index: -999; max-width: 1024px; display: none; }

#picturetoVideo .carouse .slideItem { display: table; height: 100%; }

#picturetoVideo .carouse .slideItem > div { display: table-cell; vertical-align: middle; text-align: center; }

#picturetoVideo .carouse .slideItem .banner-img { max-width: 100%; width: auto; }

.closeContentIV { position: absolute; top: 10px; right: 10px; font-size: 25px; color: #fff; font-weight: bold; cursor: pointer; z-index: 999; }

.dan999 { display: block; }

.dan998 { display: none; }

@media screen and (max-width: 999px) { .dan999 { display: none; }
  .dan998 { display: block; } }